<template>
  <div>
    <div v-if="loading && getServiceForQRCode == null">
      <v-skeleton-loader v-for="n in 5" type="list-item-avatar" :key="n">
      </v-skeleton-loader>
    </div>
    <v-data-table 
      v-else-if="getServiceForQRCode && getServiceForQRCode.length > 0" 
      align="start"
      class="timetable-table" 
      header-align="start" 
      hide-default-header 
      hide-default-footer 
      item-class="getRowClass"
      item-key="id" 
      :loading="loading" 
      role="presentation" 
      show-expand 
      single-expand 
      v-model:expanded="expanded"
      :headers="serviceHeaders" 
      :items="getServiceForQRCode" 
      :items-per-page="-1"
      @update:expanded="(newExpanded) => (expanded = newExpanded.slice(-1))"
      >
      <template v-slot:[`item.data-table-expand`]="{ item }">
        <v-btn :aria-label="this.expanded[0] == item.id ? 'Close More information' : 'Open More information'"
          color="transparent" elevation="0" icon size="small" @click="toggleExpandedRow(item)">
          <v-icon>
            {{ this.expanded[0] == item.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.service`]="{ item }">
        <strong role="text" :aria-label="ariaLabelService(item)">
          {{ this.UpperCase(item.service) }}
        </strong>
      </template>
      <template v-slot:[`item.destination`]="{ item }">
        <strong :aria-label="ariaLabelService(item)">{{ item.destination }}</strong>
        <div v-if="this.UpperCase(item.serviceMessage) == 'CANCELLED'" class="text-error">
          {{ this.UpperCase(item.serviceMessage) }}
        </div>
      </template>
      <template v-slot:[`item.due`]="{ item }">
        <strong :aria-label="ariaLabelService(item)">{{ getDepartureTimeToDisplay(item) }}</strong>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <tr v-if="showStopDescription"><td class="expanded-row-td ma-0 pa-0 stop-description-row" :colspan="columns.length">{{ stopDescriptionText(item)  }}</td></tr>
        <tr v-if="showStopDescription"><td class="expanded-row-td ma-0 pa-0 zero-height" :colspan="columns.length"></td></tr>
        <tr :class="getRowClass(item)">
          <td class="expanded-row-td ma-0 pa-0" :colspan="columns.length">
            <v-container v-if="imageSource(item.provider) != ''" class=" ma-0 pa-0" fluid>
              <v-row class="ma-0 d-flex justify-end align-center">
                <v-col :cols="5">
                  <div>Operated by</div>
                </v-col>
                <v-col class="padding-bottom d-flex justify-end align-center" :cols="7"><img class="operatorImg"
                    :alt="item.providerName" :src="imageSource(item.provider)" /></v-col>
              </v-row>
              <div v-if="isMonitored(item)" class="real-time-message ma-0 pa-0 px-5">
                Estimated journey time to stop
              </div>
              <div v-else class="real-time-message ma-0 pa-0 px-5">
                Timetable for next stops
              </div>
            </v-container>
          </td>
        </tr>

        <tr :class="getRowClass(item)">
          <td class="timeline-td" :colspan="columns.length">
            <div>
              <v-timeline align="start" class="custom-timeline" side="end">
                <v-timeline-item v-for="(stop, index) in item.via" size="small"
                  :class="getClass(index, item.via.length)" :key="index">
                  <div class="my-flex">
                    <div class="row1">
                      {{ stop.stopName }}
                      <div class="text-caption">
                        {{ stop.stopMessage }}
                      </div>
                    </div>
                    <div class="row2">
                      <div class="subsequent-stop-time me-4">{{ subsequentStopTime(stop, isMonitored(item)) }}</div>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-else>No data available.</div>

    <!-- filler to make navbar display correctly on when speech player visable -->
    <div :class="audioEnabled ? 'filler-large' : 'filler'"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { formattedTextHeader, } from "@/utils/textToSpeechUtils";
import { getTimeDifferenceFromLastLoad, } from "@/utils/vuexTimeUtils";
import {  getFormattedTime } from "@/utils/timeUtils";

export default {
  name: "UI",
  components: {},
  computed: {
    ...mapState(["AppClock", "audioEnabled", "loading", "stopDescription", "multiStop"]),
    ...mapGetters(["getServiceForQRCode"]),
    stopDescriptionText() {
      return (item) => {
        return `${this.capitalizeFirstCharacter(item.monitoredStopDescription.indicator)} ${this.capitalizeEveryWord(item.monitoredStopDescription.commonName)}`;
      };      
    },
    showStopDescription() {
      return this.multiStop;
    },
    getDepartureTimeToDisplay() {
      return (item) => {
        let timeString = '';
        if (!item.monitored) {
          timeString = getFormattedTime(item.aimedDepartureTime, "HH:mm");
        } else {
          let timedifference = getTimeDifferenceFromLastLoad(item.due);

          if (timedifference <= 0) {
            timeString = "Due";
          } else if (timedifference === 1) {
            timeString = timedifference + " min";
          } else if (timedifference > 1) {
            timeString = timedifference + " mins";
          }
        }
        return timeString;
      };
    }
  },
  data() {
    return {
      expanded: [],
      serviceHeaders: [
        {
          title: "Serv",
          align: "start",
          sortable: false,
          key: "service",
          width: "10%",
        },
        {
          title: "Destination",
          align: "start",
          sortable: false,
          key: "destination",
          width: "60%",
        },
        {
          title: "Due",
          align: "start",
          sortable: false,
          key: "due",
          width: "30%",
        },
      ],
    };
  },
  methods: {
    capitalizeEveryWord(sentence) {
      return sentence == null
        ? ""
        : sentence.split(' ')  
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                  .join(' ');
    },
    capitalizeFirstCharacter(sentence) {
      return sentence == null
        ? ""
        : sentence.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    },
  
    toggleExpandedRow(item) {
      if (this.expanded[0] === item.id) {
        this.expanded = [];
      } else {
        this.expanded = [item.id];
      }
    },
    ariaLabelService(item) {
      return formattedTextHeader(item);
    },
    isMonitored(item) {
      return item.monitored;
    },
    getRowClass(item) {
      const index = this.getServiceForQRCode.indexOf(item);
      return index % 2 === 0 ? "odd-row" : "even-row";
    },
    imageSource(provider) {
      try {
        return `https://centro.rslepi.co.uk/DownloadableFileAssets/Shared/operator_logos/${provider}.PNG`
      } catch (error) {
        return "";
      }
    },
    UpperCase(string) {
      if (string == undefined) return;
      return string.toUpperCase();
    },
    getClass(index, viasLength) {
      return index === viasLength - 1
        ? `time-line-item-last`
        : `time-line-item-${index}`;
    },
    subsequentStopTime(stop, monitored) {
      if (!monitored) {
        return getFormattedTime(stop.stopDue, "HH:mm")
      } else {
        return stop.estimatedMinutesToStop + " mins"
      }
    }
  },
};
</script>

<style>
.stop-description-row{
  height: 8px !important;
  background-color: rgb(var(--v-theme-popup-primary)) !important;
  color: rgb(var(--v-theme-on-surface-variant)) !important;
  text-align: center;
}
.zero-height {
  height: 0px !important;
  max-height:0px !important;
}

.v-data-table__td {
  padding-right: 0px !important;
}

.v-data-table__td--expanded-row {
  padding-right: 8px !important;
  padding-left: 0px !important;
}

.real-time-message {
  background-color: rgb(146, 146, 146);
  color: white;
  text-align: center;
  height: 30px;
}

.filler {
  height: 7vh;
  width: 100%;
}

.filler-large {
  height: 22vh;
  width: 100%;
}

.timeline-td {
  padding-bottom: 50px !important;
}

.expanded-row-td {
  border: none !important;
}

.timetable-table .odd-row {
  background-color: rgb(var(--v-theme-on-surface-variant)) !important;
  /* Light grey color */
  color: rgb(var(--v-theme-surface-variant-2)) !important;
}

.v-timeline-item__opposite {
  display: none !important;
}

.my-flex {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 4fr 1fr;
}

.row1 {
  grid-column: 1;
}

.row2 {
  grid-column: 2;
  align-content: flex-start;
  min-width: 100px;
}

.row3 {
  grid-column: 3;
  align-content: flex-start;
}

/* .margin-bottom {
  margin-bottom: 14vh;
} */
.padding-bottom {
  padding-bottom: 10px !important;
}

/* .right-align {
  text-align: right;
} */
.operatorImg {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 60px;
  background-color: rgb(var(--v-theme-background-dark));
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.timetable-table thead th {
  border: 10px;
  background-color: rgb(var(--v-theme-popup-primary)) !important;
  color: rgb(var(--v-theme-on-surface-variant)) !important;
}

.timetable-table thead {
  background-color: rgb(var(--v-theme-popup-primary)) !important;
}

.timetable-table th {
  border: rgb(var(--v-theme-popup-primary)) !important;
}

.timetable-table {
  font-size: 20px !important;
  border-radius: 0 !important;
}

.timetable-table .v-data-table__tr:nth-child(odd) {
  background-color: rgb(var(--v-theme-on-surface-variant)) !important;
  /* Light grey color */
  color: rgb(var(--v-theme-surface-variant-2)) !important;
  border-radius: 0;
}

/* .custom-timeline .v-timeline-divider__after,
.custom-timeline .v-timeline-divider__before {
  background-color:white;
} */
.v-data-table__tr:nth-child(even) .v-timeline-divider__after,
.v-data-table__tr:nth-child(even) .v-timeline-divider__before {
  background-color: white !important;
}

.v-data-table__tr:nth-child(odd) .v-timeline-divider__after,
.v-data-table__tr:nth-child(odd) .v-timeline-divider__before {
  background-color: black !important;
}

.custom-timeline .v-timeline-divider__dot {
  background-color: rgb(var(--v-theme-surface-variant-2)) !important;
}

.v-timeline-divider__before,
.v-timeline-divider__after,
.v-timeline-divider__dot {
  background-color: rgb(var(--v-theme-surface-variant-2)) !important;
}

.even-row .v-timeline-divider .v-timeline-divider__before,
.even-row .v-timeline-divider .v-timeline-divider__after,
.even-row .v-timeline-divider .v-timeline-divider__dot {
  background-color: rgb(var(--v-theme-divider)) !important;
}

.even-row .v-timeline-divider__inner-dot {

  background-color: rgb(var(--v-theme-background)) !important;
}


.custom-timeline .v-timeline-item:first-of-type .v-timeline-divider::before,
.custom-timeline .v-timeline-item:last-of-type .v-timeline-divider::after {
  display: none !important;
}

.time-line-item-0 .v-timeline-divider .v-timeline-divider__before,
.time-line-item-last .v-timeline-divider .v-timeline-divider__after {
  display: none !important;
}


.v-timeline-divider__inner-dot {
  background-color: white !important;
}

.time-line-item-last .v-timeline-divider__inner-dot {
  background-color: red !important;
}


.service-message {
  color: primary;
  font-size: 16px;
  font-weight: 500;
}

.text--primary {
  color: var(--v-primary);
}

.custom-class {
  background: rgb(var(--v-theme-error));
}
</style>