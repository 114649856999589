import store from '../../store/store'; 
import { timeDifference } from "@/utils/timeUtils";

export function getTimeDifferenceFromLastLoad(startTime, endTime = null) {
    const lastLoadTime = store.state.lastRefreshedDateTime; 
    const timeDiff = timeDifference(startTime, endTime || lastLoadTime);

    return timeDiff;

}
