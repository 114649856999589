<template>
  <v-app-bar :class="appBarClass">
    <img class="app-image" src="@/assets/logo.svg" alt="Transport for west midlands logo" />
    <v-toolbar-title class="app-title">  
      <div v-if="!this.multiStop" class="titleText1">{{ this.titleText1 }}</div>
      <div v-if="!this.multiStop" class="titleText2">{{ this.titleText2 }}</div>
      <div v-else class="titleText1">{{ this.DigitalCodeDescription }}</div>
    </v-toolbar-title>
    <div class="end-div" />
  </v-app-bar>
</template>

<script>
export default {
  props: {
    stopDescription: {
      type: Object,
      required: true,
    },
    multiStop: {
      type: Boolean,
      required: false,
      default: false,
    },
    digitalCodeDescription: {
      type: String,
      required: false,
      default: "",
    }
  },
  methods: {
    capitalizeEveryWord(sentence) {
      return sentence == null
        ? ""
        : sentence.split(' ')  
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                  .join(' ');
    },
    capitalizeFirstCharacter(sentence) {
      return sentence == null
        ? ""
        : sentence.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    },
  },
  computed: {
    appBarClass() {
      return "app-bar-header";
    },
    titleText1() {
      return  `${this.capitalizeFirstCharacter(this.stopDescription?.indicator ?? '')} ${this.capitalizeEveryWord(this.stopDescription?.commonName ?? '')}`
    },
    titleText2() {
      return  this.capitalizeEveryWord(this.stopDescription?.street ?? '')
    },
    DigitalCodeDescription() {
      return  this.capitalizeEveryWord(this.digitalCodeDescription ?? '')
    },
  },
};
</script>

<style scoped>
.titleText1,
.titleText2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-title {
  color: white;
  font-size: 20px;
  margin-left: 10px !important;
}

.app-image {
  height: 40px;
  width: 40px;
  margin-left: 10px;
  
}

.end-div {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.time-display {
  margin-right: 5px;
  color: white !important;
}

.app-bar-header {
  background-color: rgb(var(--v-theme-customer-main)) !important;
  color: rgb(var(--v-theme-customer-main)) !important;
}

.app-bar-header-debug {
  color: rgb(var(--v-theme-customer-main)) !important;
}
</style>