<template>
  <div>
  <div
      id="aria-live-region"
      aria-live="polite"
      aria-atomic="true"
      style="position: absolute; top: -9999px; left: -9999px; height: 1px; width: 1px; overflow: hidden;"
    >
      {{ refreshAriaEventMessage }}
    </div>
  <div class="refresh-component">
  <div class="refresh-label ma-0 pa-0" :class="getRefreshedLabelStyle"  > 
    <div :aria-label="textAriaLabel">
      Refreshed&nbsp;
    </div>
    <div :aria-label="textAriaLabel">
      {{ this.lastRefreshedDateTime.format('HH:mm:ss')}}
    </div>  
  </div>
  <div class="refresh-button" >
    <v-btn  @click="handleRefreshButtonClick" :disabled="disabledButton" variant="plain" :aria-label="buttonAriaLabel">
      <img
      class="refresh-icon"
      src="@/assets/refreshBtn.svg"
      alt="refresh icon"
      :class="disabledButton ? 'disabled-image' : ''"
      />
      <v-progress-circular  
      class="progress-circle"      
      :model-value="percentProgress"
      size="41"
      width="4"
      color="red"/>
    </v-btn>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from 'dayjs';

export default {
  data() {
    return {
      progress: 0,
      maxRefreshes: 0,
      remainingRefreshes: 0,
      lastRefreshed: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }),

      disabledButton: true,

      disableButtonTimer: null,
      intialDisabledButtonInterval: 0,
      timeUntilButtonEnabled: 0,

      autoRefreshTimer: null,
      intialAutoRefreshInterval: 0,
      timeUntilAutoRefresh: 0,

    };
  },
  mounted() {
    console.log("lastRefreshedDateTime",this.lastRefreshedDateTime)
    this.maxRefreshes = parseInt(process.env.VUE_APP_REFRESH_TIMER_MAX_REFRESHES_VALUE, 10);
    this.remainingRefreshes = this.maxRefreshes;

    this.intialDisabledButtonInterval = parseInt(process.env.VUE_APP_REFRESH_TIMER_DISABLE_VALUE, 10);
    this.timeUntilButtonEnabled = this.intialDisabledButtonInterval;

    this.intialAutoRefreshInterval = parseInt(process.env.VUE_APP_REFRESH_TIMER_VALUE, 10);
    this.timeUntilAutoRefresh = this.intialAutoRefreshInterval;


    this.startDisabledButtonTimer();
    this.startAutoRefreshTimer();
  },
  unmounted() {
    clearInterval(this.disableButtonTimer);
    clearInterval(this.autoRefreshTimer);
  },

  methods: {
    ...mapActions(["fetchData", "updateLastRefreshedDateTime"]),
    handleRefresh() {
      this.remainingRefreshes--;
      this.timeUntilAutoRefresh = this.intialAutoRefreshInterval;
      this.fetchData(this.$route.params.id);
      this.updateLastRefreshedDateTime(dayjs());
      this.startDisabledButtonTimer();

      if (this.remainingRefreshes < 1) {
         this.disabledButton = true;
        clearInterval(this.autoRefreshTimer);

        return;
      }
      this.disabledButton = true;

    },
    handleRefreshButtonClick() {
      this.handleRefresh();
    },
    startDisabledButtonTimer() {
      this.timeUntilButtonEnabled = this.intialDisabledButtonInterval;
      this.disableButtonTimer = setInterval(() => {
        this.timeUntilButtonEnabled--;
        if (this.timeUntilButtonEnabled <= 0) {
          this.disabledButton = false;
          clearInterval(this.disableButtonTimer);
        }
      }, 1000);
    },
    startAutoRefreshTimer() {
      this.autoRefreshTimer = setInterval(() => {
        this.timeUntilAutoRefresh--;
        if (this.timeUntilAutoRefresh <= 0) {
          this.handleRefresh();
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState(["refreshAriaEventMessage", "lastRefreshedDateTime"]),
    buttonAriaLabel() {
      let label = 'refresh button '
      if (this.timeUntilButtonEnabled != 0 && (this.timeUntilButtonEnabled != this.intialDisabledButtonInterval)) {
        label += `disabled for ${this.timeUntilButtonEnabled} seconds`
      } 
      return label;
    },
    textAriaLabel() { return `last refreshed ${ this.lastRefreshed }` },
    getRefreshedLabelStyle() {
      return process.env.NODE_ENV === "development" ? "last-refreshed-dev" : "last-refreshed";
    },
    percentProgress() {
      return (
        ((this.intialAutoRefreshInterval - this.timeUntilAutoRefresh) /
          this.intialAutoRefreshInterval) *
        100
      );
    },
  },
};
</script>

<style>
.refresh-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (orientation: landscape) {
    .refresh-label {
        height: 10vh; 
    }
}
.refresh-component{
  height:100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.last-refreshed {
  margin-top: 5px;
}
.last-refreshed-dev {
  /* margin-top: 10px; */
}
.button-div.v-btn--disabled {
  opacity: 1 !important;
  pointer-events: none; 
  
}

.button-div.v-btn--disabled .v-btn__content {
  color: inherit !important;
}
.progress-circle {
  position: absolute;
  margin: auto;
  
  max-height: 40px;
}
.refresh-button{
  display: flex;
  grid-area: content;
  justify-content: center;
  flex-direction: row;
  padding: 1 !important; 
  border-radius: 50% !important; 
  /* margin: 2px; */
  margin-top:-4px; 
  /* padding:2px; */
}
.refresh-icon {
  position: absolute;
  margin: auto;
  max-height: 20px;
  color: rgba(255, 50, 50, 0.5);
}
.disabled-image {
  opacity: 0.2;
}
</style>