import { createStore } from 'vuex';
import { getCookie, setCookie, deleteAllCookies } from '../cookies';
import axios from '../axios';
import dayjs from 'dayjs';


const store = createStore({
  state() {
    return {
      lastRefreshedDateTime : dayjs(),

      refreshAriaEventMessage: '',
      customer: process.env.VUE_APP_CUSTOMER_ID,
      pageTitle: `${process.env.VUE_APP_CUSTOMER_ID} Departures`,

      services: null,
      messages:[],
      loading: false,
      error: null,

      qRCodePrefix: process.env.VUE_APP_NAPTAN_PREFIX,
      qRVirtualCodePrefix: process.env.VUE_APP_VIRTUAL_CODE_PREFIX,
      appRemovesFirstThreeCharactersFromNapTAN: process.env.VUE_APP_REMOVE_FIRST_THREE_CHARACTERS_FROM_NAPTAN,


      initiallyMinimimiseMessagePriority : parseInt(process.env.VUE_APP_FOLDED_PART_SCREEN_MESSAGE_TYPE, 10),
      timeoutMessageType : parseInt(process.env.VUE_APP_UNFOLDED_PART_SCREEN_MESSAGE_TYPE,10),
      fullScreenMessageType : parseInt(process.env.VUE_APP_UNFOLDED_FULL_SCREEN_MESSAGE_TYPE,10),
      nonDismissableMessageType: parseInt(process.env.VUE_APP_FULL_SCREEN_NOT_DISMISSABLE_MESSAGE_TYPE, 10),
      
      
      splashEnabled : process.env.VUE_APP_SPLASH_SCREEN_ENABLED === "true",
      numberOfServicesToDisplay : parseInt(process.env.VUE_APP_SERVICES_TO_DISPLAY,10),


      repressServices: false,

      currentPage: 'timetable',

      highContrastEnabled: false,
      audioEnabled: false,

      splashScreenViewed: false,
      splashScreenIdLastViewed: false,
      canUseCookies: false,
      disableMenu: false,

      popupMinimised: false,
      popupPreviouslyMinimised: false,

      AppClock: new Date(),      

      qRCode: '',
      stopDescription: {
        naptanCode: '',
        atcoCode:  '',
        commonName:  '',
        indicator:  '',
        street:  '',
        localityName: '',
      },
      multiStop: false,
      digitalCodeDescription: '',
      playlist: {
        id: 123456,
        media: [
          {
            type: "image",
            alt: 'transport for west midlands buses',
            src: require("@/assets/journeo-logo-rgb-white.svg"),
            duration: 3,
          },          
          // {
          //   type: "image",
          //   src: require("@/assets/tfwm.svg"),
          //   duration: 1,
          // },
        ],
      },


      links: [
        { title: "Journeo", url: "https://journeo.com/" },
        { title: "BBC Weather", url: "https://www.bbc.co.uk/weather"},
      ],
    };
  },
  getters: {
    splashIdSameCookieValue(state) {
      return state.splashScreenIdLastViewed == state.playlist.id;
    },
    displaySplashScreen(state) {

      //if not enabled always return false
      if (!state.splashEnabled) return false
      
      //if current page is splash screen return true
      if(state.currentPage === 'splash-screen') return true
      
      //if splash screen has not been Viewed and splashId Not Same as Cookie Value return true
      if(!state.splashScreenViewed && (state.splashScreenIdLastViewed != state.playlist.id) ) return true
      
      return false;
    },

    stopDescription(state) {
      return state.stopDescription;
    },
 
    getmessagesForQRCode(state) {
      const messages = state.messages && state.messages? state.messages : null;
      return messages;
    },
    getServiceForQRCode(state) {
      const services = state.services && state.services? state.services : null;
      return services;
    },
    cookiePreference(state) {
      let canUseCookies = localStorage.getItem("vue-cookie-accept-decline-cookiepreference");
      let newValue = false;
      newValue = canUseCookies == "accept" ? true : false;

      state.canUseCookies = newValue
      return newValue;
    },
  },
  mutations: {
    SET_LASTREFRESHEDDATETIME(state, value) {
      state.lastRefreshedDateTime = value;
    },
    SET_TITLE(state, value)
    {
      document.title = value;
      state.pageTitle = value;
    },
    SET_STOP_DESCRIPTION(state, value) {
      state.stopDescription = value;
    },
    SET_DIGITAL_CODE_DESCRIPTION(state, value) {
      state.digitalCodeDescription = value;
    },
    SET_MULTI_STOP(state, value) {
      state.multiStop = value;
    },
    SET_SERVICES(state, items) {
      state.services = items;
    },
    SET_MESSAGES(state, items) {
      state.messages = items;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_REFRESH_ARIA_EVENT_MESSAGE(state, message) {
      state.refreshAriaEventMessage = message;
    },
    setPopupMinimised(state, value)
    {
      state.popupMinimised = value;
    },
    setPopupPreviouslyMinimised(state, value)
    {
      state.popupPreviouslyMinimised = value;
    },
    setCurrentPage(state, value)
    {
      state.currentPage = value;
    },

    setRepressServices(state, value)
    {
      state.repressServices = value;
    },    
    setQRCode(state, value) {      
      if (value != null
        && !state.appRemovesFirstThreeCharactersFromNapTAN
        && !value.startsWith(state.qRVirtualCodePrefix)) {
        state.qRCode = `${state.qRCodePrefix}${value}`        
      } else {    
        state.qRCode = `${value}`
      }
    },
    setAppClock(state, value) {
      state.AppClock = value;
    },
    setDisableMenu(state, value) {
      state.disableMenu = value;
    },    
    setContrast(state, value) {
      state.highContrastEnabled = value;
      if(state.canUseCookies) setCookie('highContrast', value ? '1' : '0', 365); 
    },    
    setAudio(state, value) {
      state.audioEnabled = value;
      if(state.canUseCookies) setCookie('audioEnabled', value ? '1' : '0', 365); 
    },
    setSplashScreenViewed(state) {
      state.splashScreenViewed = state.playlist.id;
      if(state.canUseCookies) setCookie('splashScreenViewed', state.playlist.id, 1);
    }, 
    setSplashScreenIdLastViewed(state, value) {
      state.splashScreenIdLastViewed = value;
      },    
    recreateAllCookies(state)
    {
      setCookie('highContrast', state.highContrastEnabled ? '1' : '0', 365)
      setCookie('audioEnabled', state.audioEnabled ? '1' : '0', 365)
      setCookie('splashScreenViewed', state.playlist.id, 1)
    },    
    deleteAllCookies()
    {
      deleteAllCookies()
    },    
    setCanUseCookies(state) {
      let canUseCookies = localStorage.getItem("vue-cookie-accept-decline-cookiepreference");
      let newValue = false;
      newValue = canUseCookies == "accept" ? true : false;

      state.canUseCookies = newValue
    },
  },
  actions: {
    async fetchData({ commit, state }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null); 
      commit('SET_REFRESH_ARIA_EVENT_MESSAGE', '');

      try {
        const response = await axios.post('/DCMS/data', {
          customer: state.customer,
          DigitalCodeId: state.qRCode
        })
        const services = convertDeparturesToServices(response.data.departures)
        const messages = convertMessages(response.data.messages);
        
        commit('SET_SERVICES', services);
        commit('SET_MESSAGES', messages);
        commit('SET_STOP_DESCRIPTION', response.data.stopDescription);        
        commit('SET_MULTI_STOP', response.data.multiStop);    
        commit('SET_DIGITAL_CODE_DESCRIPTION', response.data.digitalCodeDescription);

        commit('SET_TITLE', state.stopDescription.commonName);        
      }  catch (error) {
        console.error(error);
        commit('SET_ERROR', 'Failed to fetch data');
      } finally {
        commit('SET_LOADING', false);
        commit('SET_REFRESH_ARIA_EVENT_MESSAGE', 'Data has been reloaded');
      }

    },
    updateLastRefreshedDateTime({ commit }, payload) 
    {      
      commit('SET_LASTREFRESHEDDATETIME', payload)
    },
    updatePopupMinimised({ commit }, payload) 
    {      
      commit('setPopupMinimised', payload)
      if(payload == true) commit('setPopupPreviouslyMinimised', payload)
    },
    updateCurrentPage({ commit }, payload) {
      commit('setRepressServices', false)
      commit('setCurrentPage', payload)
    },
    updateRepressServices({ commit }, payload) {      
      commit('setRepressServices', payload)
    },

    updateContrast({ commit }, payload) {
      commit('setContrast', payload);
    },
    updateAudio({ commit }, payload) {
      commit('setAudio', payload);
    },    
    updateSplashScreenViewed({ commit }) {
      commit('setSplashScreenViewed');
    },
    initializeStore({ commit }) {
      const contrastCookie = getCookie('highContrast');
      const audioCookie = getCookie('audioEnabled');
      const splashScreenIdLastViewedCookie = getCookie('splashScreenViewed');
      if (contrastCookie !== null) {
        commit('setContrast', contrastCookie === '1');
      }
      if (audioCookie !== null) {
        commit('setAudio', audioCookie !== '0');
      }
      if (splashScreenIdLastViewedCookie !== null) {
        commit('setSplashScreenIdLastViewed', splashScreenIdLastViewedCookie);
      }
      commit('setCanUseCookies')
    },
  },
});

// Initialize store immediately 
store.dispatch('initializeStore');

function convertDeparturesToServices(departures) {
  return departures.map(departure => ({
    id: departure.id || Math.random() * 10000000000000000, // generate an id if not present
    service: departure.lineName,
    destination: departure.destination,
    startStopATCOCode: departure.originRef,
    ATCOCode: departure.monitoredStopRef,
    endStopATCOCode: departure.destinationRef,
    due: new Date(departure.expectedDepartureTime),
    monitored: departure.monitored,
    monitoredStopDescription: departure.monitoredStopDescription,
    aimedDepartureTime: new Date(departure.aimedDepartureTime + "Z"),
    provider: departure.operatorRef,
    providerName: departure.operatorName,
    providerImageSrc: departure.operatorImageSrc,
    serviceMessage: departure.status,
    orderedByTime: departure.OrderedByTime,
    via: departure.callingPoints.map(point => ({
      stopName: point.stopName,
      stopDue: new Date(point.stopDue),
      estimatedMinutesToStop: point.estimatedMinutesToStop,
      isTimingPoint: point.isTimingPoint,
      stopOrder: point.stopOrder
    })),
  }));
}

function convertMessages(messages) {
  let convertedMessages = messages.map(message => ({
    id: message.id,
    type: message.messageLocation,
    priority: message.messagePriority,
    dateTime: message.dateCreated,
    text: message.messageText.find(x=>x.lang == 'EN').text,
  }));

  return convertedMessages;
}

export default store;
