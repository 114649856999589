import dayjs from 'dayjs';


export function timeDifference(dateTime, dateTime2) {
    const date = new dayjs(dateTime);
    const date2 = dateTime2 == null ? dayjs() : new dayjs(dateTime2);
    const diff = Math.abs(date - date2);
    return Math.floor(diff / 60000);
}
export function getFormattedTime(time, format = "HH:mm") {
    var result = dayjs(time).format(format);
    return result;
}
